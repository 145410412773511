import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Container,
  CardActionArea,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import SwiperCore, { Autoplay } from "swiper/core";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/less";
import "swiper/less/navigation";
import "swiper/less/pagination";
import "swiper/css/scrollbar";
import { experimentalStyled as styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PaymentIcon from "@mui/icons-material/Payment";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import LazyLoad from 'react-lazyload';
import { Helmet } from "react-helmet";
import { getData } from "../utils";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function createData(name, visaType, fat, carbs, protein) {
  return { name, visaType, fat, carbs, protein };
}
const rows = [
  createData('eTOURIST VISA (for 30 Days)', 80),
  createData('eTOURIST VISA (for 1 Year)', 99),
  createData('eTOURIST VISA (for 5 Years)', 180),
  createData('eBUSINESS VISA', 199),
  createData('eMEDICAL VISA', 199),
  createData('eMEDICAL ATTENDANT VISA', 199),
  createData('eCONFERENCE VISA', 199),
  createData('G20 eCONFERENCE VISA', 150),
];
const cardItem = [
  {
    id: 1,
    title: "30 Days validity",
    description: " Tourist eVisa",
  },
  {
    id: 2,
    title: "1 Year validity",
    description: " Tourist eVisa",
  },
  {
    id: 3,
    title: "5 Year validity",
    description: "Tourist eVisa",
  }
];
const Item = styled(Container)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  //   marginLeft: 8,
}));

const Home = () => {
  const [blogData, setBlogData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    getDataList();
  }, [])

  const getDataList = async () => {
    const dataList = await getData()
    setBlogData(dataList.data)
  }

  SwiperCore.use([Autoplay, Pagination, Navigation]);
  const swiperRef = useRef(null);
  const navigate = useNavigate();


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apply for indian evisa online</title>
        <link rel="canonical" href="https://www.indianevisa.info/" />
        <meta name="copyright" content="https://www.indianevisa.info/" />
        <meta name="owner" content="https://www.indianevisa.info/" />
        <meta name="distribution" content="world wide" />
        <meta name="descriotion" content="Apply online indian eVisa for all citizen Complete  guide eVisa application process and time for Your Trip to India" />
      </Helmet>
      {" "}
      <div className="bg-image" style={{ maxWidth: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            px: 2,
          }}
        >
          {" "}
          <Typography
            variant="h3"
            className="text-light text-center "
            sx={{ marginTop: 7 }}
          >
            <h1>Apply For Indian Evisa Your Smooth Trip <br /> Indian Evisa Online Application</h1>
            {/* <div>Indian e-Visa Apply</div>
            <div>Apply Indian Visa Online</div> */}

            <Button
              variant="contained"
              className="mt-2 "
              size="large"
              onClick={() => {
                navigate("/apply");
              }}
            >
              Access the Online Visa Application Form
            </Button>
          </Typography>
        </Box>

        <div className="container">
          {" "}
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            breakpoints={{
              // 1300: {
              //   slidesPerView: 6,
              // },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              412: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
                // width: 20,
              },
            }}
            slidesPerView={6}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            effect={false}
            // autoplay={{
            //   delay: 2000,
            //   disableOnInteraction: false,
            // }}
            grabCursor={true}
          // onSlideChange={handleSlideChange}
          >
            <div>
              {cardItem.map((item, index) => (
                <SwiperSlide key={item.id}>
                  <Item key={index} className="">
                    <Card
                      sx={{
                        maxWidth: 400,
                        minWidth: 250,
                        borderRadius: "10px",
                        marginTop: 3,
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt="green iguana"
                        height="200"
                        image={"https://d3km7xft1sqwi7.cloudfront.net/playCard.jpeg"}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {item.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.description}{" "}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{ justifyContent: "center", marginTop: -2 }}
                      >
                        <Button size="small" variant="contained" onClick={() => navigate("/apply")}>
                          Apply Now
                        </Button>
                        {/* <Button size="small">Learn More</Button> */}
                      </CardActions>
                    </Card>
                  </Item>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-3 mt-2">
            <Card
              className="card-form"
              sx={{ borderRadius: 6, cursor: "pointer" }}
              onClick={() => navigate("/apply")}
            >
              <CardContent className="text-center">
                <LibraryBooksIcon sx={{ color: "#1976d2" }} className="fs-2" />
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Apply here for eVisa
                </Typography>
              </CardContent>
            </Card>
          </div>
          {/* {second: cards section } */}
          <div className="col-lg-3 mt-2 ">
            <Card
              className="card-form"
              sx={{ borderRadius: 6, cursor: "pointer" }}
              onClick={() => navigate("/status")}
            >
              <CardContent className="text-center">
                <AvTimerIcon sx={{ color: "#1976d2" }} className="fs-2" />
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Check your status
                </Typography>
              </CardContent>
            </Card>
          </div>{" "}
          <div className="col-lg-3 mt-2">
            <Card
              className="card-form"
              sx={{ borderRadius: 6, cursor: "pointer" }}
              onClick={() => navigate("/status")}
            >
              <CardContent className="text-center">
                <PaymentIcon sx={{ color: "#1976d2" }} className="fs-2" />
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Pay for eVisa Fees
                </Typography>
              </CardContent>
            </Card>
          </div>{" "}
          <div className="col-lg-3 mt-2">
            <Card
              className="card-form"
              sx={{ borderRadius: 6, cursor: "pointer" }}
              onClick={() => navigate("/status")}
            >
              <CardContent className="text-center">
                <CloudUploadIcon sx={{ color: "#1976d2" }} className="fs-2" />
                <Typography variant="h6">Upload Documents</Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {/* { third:articles section } */}
      {/* {fourth section } */}
      <LazyLoad height={200}>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-12 my-5">
              <div className="row d-flex align-items-center ">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="w-100 text-start">
                    <img src="/images/travel2.jpg" className="w-100" alt="travel2" />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  {/* <Card sx={{ background: "#e7e7e7" }}>
                  <CardContent> */}
                  <Typography
                    sx={{ fontSize: 14, textAlign: "justify" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <div className="mb-3">
                      {" "}
                      <h2>Explore India Hassle-Free: Apply for Indian Visa Online</h2>
                      <p>For the beginning of the unforgettable journey to India: Apply for an Indian Visa Online</p>
                      <p>Are you planning to visit India for a vacation, conference, or medical treatment? Are you looking for the easiest way to apply evisa? So, we are here to let you know how to get Indian Evisa with the help of this article.</p>
                      <p>Now there is no need for paperwork and long wait in the Embassy, you can apply for evisa from anywhere at any time at your convenience from our online platform easily and efficiently.</p>
                      <p>We provide various types of evisas: 30-day Tourist Evisa, 1-year Tourist Evisa, and 5-year Tourist Evisa according to your plans to visit India.</p>
                      <p>We welcome you to the land of varieties of culture, food, and tradition to make an unforgotten journey to India. Begin your unforgettable journey to India by applying to the Indian evisa.</p>
                      <p>Applying evisa is the simplest and easiest way and the pass to enter India</p>
                    </div>
                    <div className="mt-3">
                      Apart from the ease of applying for the Visa online the
                      e-Visa for India is also the quickest way to enter India.
                    </div>{" "}
                  </Typography>
                  {/* </CardContent>
                </Card> */}
                </div>


              </div>
            </div>
            <div className="col-lg-12 my-5">
              <div className="row d-flex align-items-center ">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">

                  <Typography
                    sx={{ fontSize: 14, textAlign: "justify" }}
                    color="text.secondary"
                  >
                    <div className="mt-0">
                      <h2>Glimpse of Indian Evisa:</h2>
                      <p>The Indian Evisa also known as the Indian Electronic Visa is issued by the government of India to foreigners to visit India for various purposes. The Evisa replaced the older paperwork, visiting consultancy to apply for Indian Evisa. The Evisa was introduced in 2014 by The Indian government in India.</p>

                      <p>There are various types of Evisa tourist evisa, business evisa, medical evisa, conference evisa all evisas depend upon various activities or purposes for which foreigners are visiting India. Foreigners can categorize the evisa depending on the purpose of visiting India.</p>

                      <p>The process of evisa is simple, efficient and convenient for travellers travelling to India. Travellers can apply online on our website <a href="https://indianevisa.info" target="_blank">https://indianevisa.info</a> and can easily and conveniently get the evisa on their email ids. For evisa travellers need to upload a face photo, passport photo and other documents depending on the type of evisa and then make the payment online and wait for approval of Evisa. Approval of Evisa usually takes 72 hours of processing time. Even the payment needs to be made online.</p>

                      <p>After the uploading of important documents and payment can avail of the evisa on the respective emails. Travellers need to show the Evisa to the immigration authorities at the time of arrival.</p>
                    </div>
                  </Typography>

                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => navigate("/apply")}
                  >
                    APPLY INDIAN VISA ONLINE{" "}
                  </Button>

                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="w-100 ">
                    <img src="/images/travel4.avif" className="w-100" alt="travel4" />
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="row d-flex align-items-center my-5">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="w-100 text-start">
                <img src="/images/travel.avif" className="w-100" alt="travel" />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <Typography
                sx={{ fontSize: 14, textAlign: "justify" }}
                color="text.secondary"
                gutterBottom
              >
                <div className="mb-3">
                  <h2>Note on Categories of Evisa:!</h2>
                  <p>Travellers can apply for evisas depending upon their need and purpose of visiting India. If you are visiting India to meet your friends, family or relative, sight seeing, tourism, short term yoga programme then you must go for tourist evisa. Tourist evisa are of three types 30 day, 1year and 5 year evisa. For more details about tourist evisa you can read <a href="https://indianevisa.info/blog/tourist-e-visa-in-india-551107" target="_blank">https://indianevisa.info/blog/tourist-e-visa-in-india-551107.</a></p>

                  <p>For attending business meetings, for new opportunities in India, or for any business-related purpose you are coming to India you can apply for business evisa.</p>

                  <p>Likewise if you are visiting India for medical purposes you can apply for medical evisa</p>
                </div>
              </Typography>
            </div>

          </div>

          {/* </div> */}
        </div>
      </LazyLoad>
      {/* {fifth:price table } */}
      <LazyLoad height={200}>
        <div className="w-100 px-5 d-flex justify-content-center py-5 w-75 overflow-auto">

          <table className="w-100 bg-light rounded " style={{ minWidth: 400 }}>
            <thead className="text-white" style={{ backgroundColor: "#1976d2", fontSize: "20px" }}>
              <tr className="border">
                <th className="p-3">Visa Type</th>
                <th className="border p-3 text-end">Price($)</th>
              </tr>
            </thead>
            <tbody >
              {rows.map((row) =>
                <tr className="border-right-0 border border-black" style={{ backgroundColor: "#e6f3ff" }}>
                  <td className="border p-3 "><Link to="/apply" style={{ textDecoration: "none" }}>{row?.name}</Link></td>
                  <td className="border p-3 text-end">{row?.visaType}</td>
                </tr>
              )}
            </tbody>
          </table>

        </div>
      </LazyLoad>

      <LazyLoad height={200}>
        <div className="container">
          <div className="row py-5">
            <div className="d-flex justify-content-center px-5 ">
              <Typography variant="h4" color="primary" >Articles</Typography>
            </div>

            <div className="d-flex gap-3 justify-content-center flex-wrap">
              {
                blogData?.slice(0, 3).map(el =>
                  <div className=" mt-4">
                    <Link to={`/blog/${el.slug}`}>
                      <Card sx={{ maxWidth: 345, width: 440 }} className="position-relative hover">
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="300"
                            image={el?.thumbnail}
                            alt="img"
                          />
                          <CardContent className="position-absolute fixed-bottom text-white h-100 d-flex flex-column-reverse" style={{ backgroundColor: "rgb(0 0 0 / 32%)" }}>
                            <Typography gutterBottom variant="h6" component="div">
                              {el.title}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </div>
                )
              }
            </div>
            <div className="w-100 text-center mt-4">
              <Button variant="contained"><Link to="/blogs" style={{ textDecoration: "none", color: "white" }}>See more</Link></Button>

            </div>
          </div>
        </div>
      </LazyLoad>

      {/* <Footer /> */}
    </>
  );
};
export default Home;
