import React, { useEffect, useState } from "react";
import {
  stack,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Checkbox,
  Typography,
  Card,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Container,
  Stack,
} from "@mui/material";

import { PayPalButton } from "react-paypal-button-v2";
import "../Components/Css/payCheck.css";
import { createOrderOnBackend, Payment, StripePayment, validateRazorpayOrder } from "../utils";
import Loading from "./Loading";
import Loader from "./Loader";
import { useNavigate, Navigate } from "react-router-dom";

//Stripe Import and setup
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import StripeSuccess from "./postPayment/StripeSuccess";
import Error from "./postPayment/Error";
import axios from "axios"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentCheck() {
  const [isLoading, setIsLoading] = useState(false);
  const [stripePayment, setStripePayment] = useState({})
  const [isRazorpaySucc, setIsRazorpaySucc] = useState(false)
  const [isRazorpayFail, setIsRazorpayFail] = useState(false)
  const navigate = useNavigate();

  const handleClik = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const result = await Payment();
    if (result.name === "AxiosError") {
      setIsLoading(false);
      console.log("Error");
      navigate("/payment-error");
    } else {
      setIsLoading(false);

      window.location.replace(result.data.approverUrl);
    }
  };
  useEffect(() => {
    console.log(isLoading);
  }, isLoading);


  const handleStripe = async () => {
    setIsLoading(true);
    const result = await StripePayment();
    if (result.name === "AxiosError") {
      setIsLoading(false);
      console.log("Error");
      navigate("/payment-error");
    } else {
      setIsLoading(false);
      const options = {
        // passing the client secret obtained from the server
        clientSecret: result.data.clientSecret,
        theme: 'night'
      };
      setStripePayment({ options, price: result.data.price })
    }
  }
  const handlePayment = async () => {
    setIsLoading(true);
    const order = await createOrderOnBackend(); // Fetch order from your backend

    const options = {
      "key": process.env.REACT_APP_RAZORPAY_TEST_KEY,
      "amount": order.amount,
      "currency": order.currency,
      "name": "Indian E-Visa",
      "description": "",
      "order_id": order.id,
      "handler": async function (response) {
        console.log(response)
        try {
          const checkPayment = await validateRazorpayOrder(response)
          console.log(true, checkPayment.data)
          if (checkPayment.data.status) {
            let pdf = await axios.get(`${process.env.REACT_APP_BASE_URL}/visa/pdf/visa/${localStorage.getItem("application_id")}`)
            setIsRazorpaySucc(true)
          } else {
            setIsRazorpayFail(true)
          }
        } catch (error) {
          console.log(false, error)
        }
      },
      "theme": {
        "color": "#1976D2"
      }
    };

    setIsLoading(false)

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };


  if (stripePayment.options) return (
    <Elements stripe={stripePromise} options={stripePayment.options}>
      <CheckoutForm price={stripePayment.price} />
    </Elements>
  )

  if (isRazorpaySucc) return (<StripeSuccess />)
  if (isRazorpayFail) return (<Error />)

  return (
    <Stack
      spacing={4}
      sx={{ display: "flex", alignItems: "center", margin: 10 }}
    >
      {isLoading && <Loader />}
      <Typography variant="h4"> Make Payment</Typography>

      { }
      <div style={{
        display: "flex",
        gap: "10px"
      }}>
        {/* <Button
          variant="contained"
          size="small"
          // color=""
          onClick={handleClik}
          style={{

            background: "#FF9933",
          }}
        >
          <img
            src="https://help.instapage.com/hc/article_attachments/360002241868/PayPal.svg.png"
            height={"50px"}
            width="210px"
            alt="paypal"
          />
        </Button> */}
        <Button
          variant="contained"
          size="small"
          // color=""
          onClick={handlePayment}
          style={{

            background: "#FF9933",
          }}
        >
          <img
            src="https://cdn.worldvectorlogo.com/logos/razorpay.svg"
            height={"50px"}
            width="210px"
            alt="paypal"
          />
        </Button>
        {/* <Button
          variant="contained"
          size="small"
          // color=""
          onClick={() => { handleStripe() }}
          style={{

            background: "#FF9933",
          }}
        >
          <img
            src="/images/stripe.png"
            style={{
              height: "auto",
              width: "210px",
              maxHeight: "80px",
              objectFit: "contain",
            }}
          />
        </Button> */}
      </div>
    </Stack>

  );
}

export default PaymentCheck;
