import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

function Blog() {
  const params = useParams();
  const { slug } = params;
  const [blogData, setBlogData] = useState({})

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blog/view/slug/${slug}`)
      .then((res) => {
        setBlogData(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <div style={{ marginTop: "40px", padding: "0px 30px" }}>
      {
        blogData ?
          <Helmet>
            <meta name="description"
              content={blogData.metaDescription ? blogData.metaDescription : "Indian Evisa"} />
            <title> {blogData.metaTitle ? blogData.metaTitle : "Indian Evisa"}</title>
          </Helmet>
          :
          <></>
      }
      <h1 style={{ textAlign: "center", marginTop: "50px", textTransform: "capitalize" }}>{blogData?.title}</h1>
      <div style={{ padding: "0px 20px", marginBottom: "50px" }}>
        {
          parse(blogData.description ? blogData.description : "<h1></h1>")
        }
      </div>

    </div>
  )
}

export default Blog