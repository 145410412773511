import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getStatus = async (id) => {
  const response = await axios
    .get(`${BASE_URL}/visa/status/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

export const UploadDocuments = async (formData, id) => {
  console.log(formData, id);
  const response = axios
    .post(`${BASE_URL}/visa/uploads/${id}`, formData)
    .then((res) => {
      return res;
    })
    .catch((error) => console.error("Error:", error));

  return response;
};

export const FileList = async (id) => {
  const response = axios
    .get(`${BASE_URL}/visa/file/list/${id}`)
    .then((res) => {
      console.log("utils", res);
      return res;
    })
    .catch((error) => console.error("Error:", error));

  return response;
};

export const CreateForm = async (values, stage, id) => {
  console.log(values, stage, id);

  const headers = {
    "Content-Type": "application/json",
  };
  const requestBody = {
    currentStage: stage,
    id: id,
    answers: values,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/visa/apply`,
      requestBody,
      { headers }
    );
    console.log(response);
    console.log(response.data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const UpdateForm = async (values, stage, id) => {
  console.log(values, stage, id);

  const headers = {
    "Content-Type": "application/json",
  };
  const requestBody = {
    currentStage: stage,
    id: id,
    answers: values,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/visa/apply`,
      requestBody,
      { headers }
    );
    console.log(response);
    console.log(response.data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ContactApi = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await axios
    .post(`${BASE_URL}/query`, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
  // return response;
};

export const Payment = async () => {
  //   let token = localStorage.getItem('token')
  // const headers = {
  //   "Content-Type": "application/json",
  //   'Authorization': `Bearer ${token}`
  // };
  let appId = localStorage.getItem("application_id");

  const response = await axios
    .get(`${BASE_URL}/order/${appId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const StripePayment = async () => {
  //   let token = localStorage.getItem('token')
  // const headers = {
  //   "Content-Type": "application/json",
  //   'Authorization': `Bearer ${token}`
  // };
  let appId = localStorage.getItem("application_id");

  const response = await axios
    .get(`${BASE_URL}/order/stripe/${appId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const PaymentValidate = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const appId = localStorage.getItem("application_id");
  console.log(appId);

  const response = await axios
    .post(`${BASE_URL}/order/validate-order/${appId}`, data, {
      headers,
    })
    .then((res) => {

      if (res.status === 200) {
        axios.get(`${BASE_URL}/visa/pdf/visa/${appId}`, {
          headers,
        })
      }
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
  // return response;
};
export const createOrderOnBackend = async (amount) => {
  try {
    let appId = localStorage.getItem("application_id");
    // Replace with the actual endpoint URL and port
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/order/razorpay/${appId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    const data = await response.json();

    if (response.ok) {
      return data; // This should contain the order details including the order ID
    } else {
      console.error("Failed to create order on backend", data);
      // Handle errors here (e.g., display an error message to the user)
    }
  } catch (error) {
    console.error("Error in createOrderOnBackend", error);
    // Handle network errors here
  }
};

export const validateRazorpayOrder = async (body) => {
  try {
    let appId = localStorage.getItem("application_id");
    // Replace with the actual endpoint URL and port
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/order/verify-payment`, body);
    return response
  } catch (error) {
    console.error("Error in createOrderOnBackend", error);
    // Handle network errors here
  }
};


export const getData = async () => {
  const response = await axios
    .get(`${process.env.REACT_APP_BASE_URL}/blog/view?isPublished=true`)
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
};


