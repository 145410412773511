import { Box, Card, CardActions, CardContent, Typography } from "@mui/material"

const Error = ()=>{

    return(
        <Box
        sx={{
            width: '100%',
            height: '30vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center' ,
          }}>
            <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h4">Payment Unsuccessful</Typography>
            </CardContent>
            <CardContent className="d-flex justify-content-center">
              <img
                src={require("../../assets/failed.png")}
                height={"100px"}
                width={"100px"}
                alt="Oh no!"
              />
            </CardContent>
            <CardActions className="d-flex justify-content-center">
            </CardActions>
          </Card>
        </Box>
    )
}

export default Error