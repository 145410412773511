import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import "./Css/notFound.css"

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md" className='not-found-desktop'>
        <Grid container spacing={2}>
          <Grid xs={6} className='not-found-data'>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained" onClick={()=>{window.location.href = "https://www.indianevisa.info/"}}>Back Home</Button>
          </Grid>
          <Grid xs={6}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              alt="404"
              width={500} height={250}
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" style={{width:"90vw"}} className='not-found-mobile'>
          <Grid xs={6} className='not-found-data'>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6" style={{flexWrap:"wrap",width:"80%"}}>
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained" onClick={()=>{window.location.href = "https://www.indianevisa.info/"}}>Back Home</Button>
          </Grid>
      </Container>
    </Box>
  );
}