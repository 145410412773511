import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideMenu from "../../Components/SideMenu/index";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import Ckeditor from "../Ckeditor";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function EditBlog() {
  const params = useParams()
  const { id } = params
  const navigate = useNavigate()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("<p>Please write Blog</p>")
  const [file, setFile] = useState("")
  const [metaTitle, setMetaTitle] = useState("")
  const [metaDescription, setMetaDescription] = useState("")


  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/blog/viewsingle/${id}`)
      .then((res) => {
        setTitle(res.data.title)
        setDescription(res.data.description)
        setFile(res.data.thumbnail)
        setMetaTitle(res.data.metaTitle)
        setMetaDescription(res.data.metaDescription)
      }).catch((err) => {
        console.log(err)
      })
  }

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const handleSubmit = (isPublish) => {
    console.log(title, description)
    const formData = new FormData()
    formData.append("title", title)
    formData.append("description", description)
    formData.append("file", file)
    formData.append("lastUpdate", new Date())
    formData.append("metaTitle", metaTitle)
    formData.append("metaDescription", metaDescription)
    axios.put(`${process.env.REACT_APP_BASE_URL}/blog/update/${id}`, formData).then((res) => {
      console.log(res)
      if (isPublish) {
        axios.put(`${process.env.REACT_APP_BASE_URL}/blog/ispublish/${id}`).then((res) => {
          toast.success("Blog Created!!")
          navigate("/admin/blogs")
        }).catch((err) => {
          toast.error("Something Went Wrong!!")
          console.log(err)
        })
      } else {
        toast.success("Blog Created!!")
        navigate("/admin/blogs")
      }
    }).catch((err) => {
      toast.error("Something Went Wrong!!")
      console.log(err)
    })
  }

  return (
    <Box sx={{ display: "flex" }}>
      <SideMenu></SideMenu>
      <ToastContainer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5"> Edit Blogs</Typography>
          <Link to="/admin/blogs/create">
          </Link>
        </Box>

        <Box>
          <TextField style={{ margin: "20px 0px" }} value={title} onChange={(e) => {
            setTitle(e.target.value)
          }} fullWidth id="outlined-basic" label="Title" variant="outlined" />
          <Ckeditor description={description} setDescription={setDescription} />
          {/* <form onSubmit={handleSubmit}> */}

          <TextField style={{ margin: "20px 0px" }} value={metaTitle} onChange={(e) => {
            setMetaTitle(e.target.value)
          }} fullWidth id="outlined-basic" label="Meta Title" variant="outlined" />

          <TextField style={{ margin: "0px" }} value={metaDescription} onChange={(e) => {
            setMetaDescription(e.target.value)
          }} fullWidth id="outlined-basic" label="Meta Description" variant="outlined" />


          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} sx={{ marginTop: 3 }}>
            {/* Upload file */}
            <input type="file" onChange={(e) => { setFile(e.target.files[0]) }} />
          </Button>
          {/* </form> */}
          <div style={{ margin: "20px 0px", display: 'flex', gap: "10px" }}>
            <Button variant="contained" onClick={() => { handleSubmit(false) }}>Save</Button>
            <Button variant="contained" onClick={() => { handleSubmit(true) }}>Save & Publish</Button>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
